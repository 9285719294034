import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export function Landing() {
  const navigate = useNavigate();
  const preloadWizard = () => import('../plan-genius-wizard/PlanGeniusWizard');

  useEffect(() => {
    preloadWizard();
  }, [])
  
  return <Flex height="100vh" alignItems="flex-end" backgroundColor="blackAlpha.400" pb={[24, 24, 0]}>
    <video autoPlay loop muted playsInline className="background-video">
      <source src="plangenius.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <Flex p={[4, 4, 8]} gap={[4, 4, 8]} flexDirection={['column', 'column', 'row']} backgroundColor={["whiteAlpha.800", "whiteAlpha.800", "whiteAlpha.700"]} width="100%" alignItems="center" justifyContent="space-around">
      <Flex width={['100%', '100%', 'auto']} gap={[4, 4, 8]} flexDirection={['row', 'row', 'column']} alignItems="center" justifyContent={['space-between', 'space-between', 'initial']}>
        <Image src="/logo.png" maxHeight={['30px', '30px', '50px']} alt="Plan Genius" />
        <Grid
          backgroundColor="#00346A"
          _hover={{ backgroundColor: '#004184' }}
          _active={{ backgroundColor: '#002b5c' }}
          transition="background-color 0.2s"
          fontWeight="semibold"
          fontSize={['sm', 'sm', 'lg']}
          placeItems="center"
          borderRadius="lg"
          p={[2, 2, 4]}
          cursor="pointer"
          color="white"
          minWidth={['120px', '120px', '250px']}
          onClick={() => navigate('/wizard')}
        >Get Started</Grid>
      </Flex>
      <Box width={['auto', 'auto', '50%']} fontSize={['sm', 'sm', 'xl']} fontWeight="semibold">
        <Text mb={[2, 2, 4]}>
          Transform your business idea into a winning plan with our FREE AI-powered Plan Builder.
        </Text>
        <Text>
          Enter basic info, get expert suggestions and solid financial projections—all designed to let you focus on what matters most: your business.
        </Text>
      </Box>
    </Flex>
  </Flex>
}
